<template>
  <div>
    <van-action-sheet v-model="_BaozhengJin" title="竞拍保证金">
      <div class="BaozhengJin">
        <ul>
          <li class="BaozhengJin-item" style="font-size: 25px; color: #E54320;">{{ zhaoJin.margin }}</li>
          <li class="BaozhengJin-item">所需{{ zhaoJin.auctionTypeName }}({{ zhaoJin.danwei }})</li>
          <li class="BaozhengJin-item BaozhengJin-shuo">1、中拍后支付时保证金用于抵扣</li>
          <li class="BaozhengJin-item BaozhengJin-shuo">2、若中排后逾期未支付贷款，保证金全额扣除。</li>
          <li class="BaozhengJin-item BaozhengJin-shuo">3、未中拍保证金原路退回。</li>
          <li class="BaozhengJin-item BaozhengJin-shuo">
            <van-checkbox v-model="checked" checked-color="#E54320" icon-size="15"></van-checkbox>
            <span style="margin: 0 5px;">我已同意</span>
            <span style="color:#E54320;" @click="enterShuomin">《win生活参拍细则》</span>
          </li>
        </ul>

        <div style="margin-top: 25px;">
          <van-button round block color="#E54320" @click="zhifu">立即支付</van-button>
        </div>
      </div>
    </van-action-sheet>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>

import { Toast } from 'vant'
import { zhifuBaoJin } from '@/api/Ge/port'
import Loading from './Loading'

export default {
  components: { Loading },

  props: {
    BaozhengJin: {
      type: Boolean
    },
    zhaoJin: {
      type: Object
    }
  },

  computed: {
    _BaozhengJin: {
      get () {
        return this.BaozhengJin
      },
      set () {
        this.checked = false
        this.$emit('closeBaozhengJin')
      }
    }
  },

  data () {
    return {
      checked: false,
      loading: false
    }
  },

  methods: {
    enterShuomin () {
      this.$router.push('/southShuoming')
    },

    zhifu () {
      if (this.checked) {
        this.loading = true
        zhifuBaoJin({ id: this.zhaoJin.id }).then(res => {
          if (res.data.code === 0) {
            Toast('支付成功')
            this._BaozhengJin = false
            this.loading = false
            this.$emit('zhifuSuccess')
          } else {
            this.loading = false
            Toast(res.data.message)
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        Toast('请勾选win生活参拍规则')
      }
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.BaozhengJin {
  padding: 25px;
  box-sizing: border-box;
  font-size: 14px;
  color: #878787;
  &-item {
    text-align: center;
    margin-bottom: 10px;
  }
  &-shuo {
    text-align: left;
    display: flex;
    align-items: center;
  }
}
</style>