<template>
  <div class="borderContainer"
    :style="{ paddingBottom: status == '0' ? '2.973rem' : (status == '1' || status == '2') ? '3.7838rem' : '3.5135rem' }">
    <van-nav-bar title="商品详情" fixed placeholder @click-left="onClickLeft" left-arrow />

    <div class="container">
      <div class="container-swipe">
        <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
          <van-swipe-item v-for="(x, i) in pic" :key="i">
            <van-image lazy-load fit="cover" :src="x" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="shangpin box" v-time-isshow>
      <p class="shangpin-name">{{ info.goodsName }}</p>
      <div class="shangpin-price">
        <span class="shangpin-price-type">当前价({{ info.auctionTypeName }})：</span>
        <span class="shangpin-price-num">{{ info.bidding }}</span>
      </div>

      <div class="shangpin-start" v-if="status == '0'">
        <img class="shangpin-start-img" src="./img/dian.png" alt="">
        <span class="shangpin-start-now">即将开始</span>
        <span>{{ m_d }}</span>
      </div>

      <div class="shangpin-noneCanjia" v-if="status == '1' || status == '2' || status == '3'">
        <div class="shangpin-noneCanjia-left">
          <van-tag color="#fff" text-color="#E54320">拍卖中</van-tag>
          <p style="margin-left: 0.1351rem;">已有{{ info.count }}人出价</p>
        </div>

        <div class="shangpin-noneCanjia-right">
          <span class="shangpin-noneCanjia-right-jieshu">距结束&nbsp;:&nbsp;</span>
          <span>
            <van-count-down :time="endTime" @finish="finish">
              <template #default="timeData">
                <span class="block">{{ timeData.days }}</span>
                <span class="colon">天</span>
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </span>
        </div>
      </div>

      <div class="shangpin-noneCanjia" v-if="status == '4'">
        <div class="shangpin-noneCanjia-left">
          <van-tag color="#fff" text-color="#E54320">拍卖中</van-tag>
          <p style="margin-left: 0.1351rem;">已有{{ info.count }}人出价</p>
        </div>
        <div class="shangpin-noneCanjia-right">
          <span class="shangpin-noneCanjia-right-jieshu">已结束</span>
        </div>
      </div>
    </div>

    <div class="jine box">
      <div class="jine-item">
        <div class="jine-item-group">
          <span class="jine-item-group-type">保证金({{ info.auctionTypeName }})：</span>
          <span class="jine-item-group-num">{{ info.margin }}{{ info.danwei }}</span>
        </div>

        <div class="jine-item-group">
          <span class="jine-item-group-type">起始价({{ info.auctionTypeName }})：</span>
          <span class="jine-item-group-num">{{ info.auctionPrice }}{{ info.danwei }}</span>
        </div>
      </div>

      <div class="jine-item jine-main">
        <div class="jine-item-group">
          <span class="jine-item-group-type">加价幅度({{ info.auctionTypeName }})：</span>
          <span class="jine-item-group-num">{{ info.markUpPrice }}{{ info.danwei }}</span>
        </div>

        <div class="jine-item-group">
          <span class="jine-item-group-type">延时周期：</span>
          <span class="jine-item-group-num">{{ info.delayTime }}秒</span>
        </div>
      </div>

      <div class="jine-item">
        <div class="jine-item-group">
          <span class="jine-item-group-type">竞拍周期：</span>
          <span class="jine-item-group-num">{{ info.startTime }} - {{ info.endTime }}</span>
        </div>
      </div>
    </div>

    <div class="jingpaiReaords box" v-if="orderAuctions.length">
      <div class="jingpaiReaords-header">
        <span class="jingpaiReaords-header-left">竞拍记录</span>
        <span class="jingpaiReaords-header-right">{{ orderAuctions.length }}条</span>
      </div>

      <div>
        <div class="jingpaiReaords-item" v-for="(x, i) in orderAuctions" :key="x.id">
          <van-image width="40" height="40" round lazy-load fit="cover" :src="x.headimgUrl" />
          <div class="jingpaiReaords-item-main">
            <div class="jingpaiReaords-item-main-detail">
              <span class="jingpaiReaords-item-main-detail-one">{{ x.nickName }}</span>
              <div class="jingpaiReaords-item-main-detail-two">
                <div class="jingpaiReaords-item-main-detail-two-text" v-if="i == 0">领先</div>
                <div class="jingpaiReaords-item-main-detail-two-text" style="background: #E6E6E6; color: #000;" v-else>出局
                </div>
              </div>
              <span class="jingpaiReaords-item-main-detail-three">{{ x.bidding }}{{ info.auctionTypeName }}</span>
            </div>
            <div class="jingpaiReaords-item-main-time">{{ x.updateTime }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="commodityDetail">
      <div class="commodityDetail-title">
        <div></div>
        <span>商品详情</span>
        <div></div>
      </div>

      <div class="commodityDetail-img" v-html="info.goodsDetails"></div>
    </div>

    <div class="goodsRules box">
      <div class="goodsRules-rules">
        <div class="goodsRules-rules-item">
          <span class="goodsRules-rules-item-rule">竞拍规则</span>
        </div>

        <div class="goodsRules-rules-item">
          <span class="goodsRules-rules-item-name" @click="enterShuomin">更多说明</span>
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="goodsRules-list">
        <img class="goodsRules-list-img" src="./img/flow.png" alt="">
      </div>
    </div>

    <div class="daohang">
      <div class="daohang-start" v-if="status == '0'">
        <img class="daohang-start-img" src="./img/dian.png" alt="">
        <span class="daohang-start-now">即将开始</span>
        <span>{{ m_d }}</span>
      </div>

      <div class="daohang-noneCanjia" v-if="status == '1' || status == '2' || status == '3'">
        <div class="daohang-noneCanjia-left">
          <p>
            <span>当前价 </span>
            <span style="color: #E54320;">{{ info.auctionTypeName }}{{ info.bidding }}{{ info.danwei }}</span>
          </p>
          <p>加价幅度：{{ info.auctionTypeName }}{{ info.markUpPrice }}{{ info.danwei }}</p>
        </div>
        <div class="daohang-noneCanjia-right">
          <span class="daohang-noneCanjia-right-jieshu">距结束&nbsp;:&nbsp;</span>
          <span>
            <van-count-down :time="endTime" @finish="finish">
              <template #default="timeData">
                <span class="block">{{ timeData.days }}</span>
                <span class="colon">天</span>
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </span>
        </div>
      </div>

      <div class="daohang-noneCanjia" v-if="status == '4'">
        <div class="daohang-noneCanjia-left">
          <p>当前价：{{ info.auctionTypeName }}{{ info.bidding }}{{ info.danwei }}</p>
          <p>加价幅度：{{ info.auctionTypeName }}{{ info.markUpPrice }}{{ info.danwei }}</p>
        </div>
        <div class="daohang-noneCanjia-right">
          <span class="daohang-noneCanjia-right-jieshu">已结束</span>
        </div>
      </div>

      <div class="daohang-canpai">
        <div class="daohang-canpai-left" @click="contact">
          <van-icon name="service" size="0.5405rem" />
          <span class="daohang-canpai-left-chat">客服</span>
        </div>

        <div class="daohang-canpai-right">
          <div v-if="remind == '0' && status == '0'" class="daohang-canpai-right-btn" @click="guanzhutixing">
            <span>关注提醒</span>
          </div>

          <div v-if="remind == '1' && status == '0'" class="daohang-canpai-right-btn">
            <span>已关注提醒</span>
          </div>

          <div class="daohang-canpai-right-btn" v-if="status == '1'" @click="openBaozhengJin">
            <p style="font-size: 0.3784rem;">立即参拍</p>
            <p style="font-size: 0.3243rem;">保证金({{ info.auctionTypeName }}):{{ info.margin }}{{ info.danwei }}</p>
          </div>

          <div class="daohang-canpai-right-btn" v-if="status == '2' || status == '3'" @click="chujia">
            <span>出价</span>
          </div>

          <div class="daohang-canpai-right-btn" v-if="status == '4'">
            <span>结束</span>
          </div>
        </div>
      </div>
    </div>

    <loading :loading="loading" @closeLoading="closeLoading" />

    <baozheng-jin 
      :BaozhengJin="BaozhengJin" 
      :zhaoJin="zhaoJin" 
      @closeBaozhengJin="closeBaozhengJin"
      @zhifuSuccess="zhifuSuccess" />

    <chu-jia 
      :ChuJia="ChuJia" 
      :chujiainfo="chujiainfo" 
      @closeChuJia="closeChuJia" 
      @zhifuSuccess="zhifuSuccess" />
  </div>
</template>

<script>

import { getSouthDetail, guanzhutixing } from '@/api/Ge/port'
import BaozhengJin from './components/BaozhengJin'
import ChuJia from './components/ChuJia'
import Loading from './components/Loading'
import { Toast } from 'vant'
import { contact } from "@/common"

export default {
  components: { BaozhengJin, ChuJia, Loading },

  data() {
    return {
      pic: [],
      status: '0', // 0未开始  1 开始未交 2 开始已交 3 未出价 4 结束
      timeData: {
        hours: '',
        minutes: '',
        seconds: ''
      },
      endTime: 0,
      imAccid: '',
      info: {},
      loading: false,
      zhaoJin: {},
      orderAuctions: [],
      chujiainfo: {},
      BaozhengJin: false,
      ChuJia: false,
      remind: '0',
      m_d: '',
      xianyin: '1'
    }
  },

  created () {
    if (this.$route.query.session) {
      this.$store.commit('setSession', this.$route.query.session)
    }
    this.getPage()
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
  },

  directives: {
    timeIsshow: {
      inserted() {
        window.onscroll = function () {
          let top = document.documentElement.scrollTop || document.body.scrollTop
          if (top > 340) {
            this.xianyin = '2'
          }
        }
      }
    }
  },

  methods: {
    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    enterShuomin () {
      this.$router.push('/southShuoming')
    },

    contact () {
      if (this.imAccid) {
        contact(this.imAccid)
      } else {
        Toast('暂无客服')
      }
    },

    closeBaozhengJin () {
      this.BaozhengJin = false
    },

    zhifuSuccess () {
      this.getPage()
    },

    guanzhutixing () {
      this.loading = true
      guanzhutixing({ id: this.info.id }).then(res => {
        if (res.data.code === 0) {
          Toast('已关注提醒')
          this.loading = false
          this.getPage()
        } else {
          this.loading = false
          Toast(res.data.message)
        }
      })
    },

    openBaozhengJin () {
      this.BaozhengJin = true
      let zhaoJin = {
        margin: this.info.margin,
        auctionTypeName: this.info.auctionTypeName,
        danwei: this.info.danwei,
        id: this.info.id
      }
      this.zhaoJin = zhaoJin
    },

    closeChuJia () {
      this.ChuJia = false
    },

    openChuJia () {
      this.ChuJia = true
    },

    finish () {
      this.getPage()
    },

    chujia () {
      this.getPage(true)
    },

    chujiaIng (count, bidding, auctionPrice, markUpPrice) {
      this.ChuJia = true
      let chujiainfo = {
        auctionTypeName: this.info.auctionTypeName,
        bidding: count == 0 ? Number(auctionPrice) : Number(bidding) + Number(markUpPrice),
        danwei: this.info.danwei,
        id: this.info.id
      }
      this.chujiainfo = chujiainfo
    },

    getPage (updateBoolean) {
      this.loading = true
      getSouthDetail({ id: this.$route.query.id }).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.pic = res.data.data.pic
          this.remind = res.data.data.remind
          this.imAccid = res.data.data.imAccid

          res.data.data.orderAuctions.forEach(x => {
            let paymentTypeName = ''
            let danwei = ''
            switch (x.paymentType) {
              case '1': paymentTypeName = '驿马', danwei = '匹'; break
              case '2': paymentTypeName = '金叶子', danwei = '个'; break
              case '3': paymentTypeName = '官凭', danwei = '个'; break
            }
            x.paymentTypeName = paymentTypeName
            x.danwei = danwei
          })
          this.orderAuctions = res.data.data.orderAuctions

          let info = res.data.data
          this.status = res.data.data.status

          let danwei = ''
          let auctionTypeName = ''
          switch (info.auctionType) {
            case '1': auctionTypeName = '驿马', danwei = '匹'; break
            case '2': auctionTypeName = '金叶子', danwei = '个'; break
            case '3': auctionTypeName = '官凭', danwei = '个'; break
          }

          info.danwei = danwei
          info.auctionTypeName = auctionTypeName

          this.info = info

          // 结束倒计时 status 1 2
          if (info.status == '1' || info.status == '2' || info.status == '3') {
            let date1 = new Date().getTime()
            let date2 = new Date(info.endTime.replace(/-/g, '/')).getTime()
            let endTime = date2 - date1
            if (endTime > 0) {
              this.endTime = endTime
            } else {
              this.endTime = 0
            }
          }

          // 开始时间
          if (info.status == '0') {
            let date = new Date(info.startTime.replace(/-/g, '/'))
            const m = (date.getMonth() + 1).toString().padStart(2, '0')
            const d = (date.getDate()).toString().padStart(2, '0')
            const h = (date.getHours()).toString().padStart(2, '0')
            const f = (date.getMinutes()).toString().padStart(2, '0')
            const m_d = `${m}月${d}日 ${h}:${f} 开始`
            this.m_d = m_d
            this.zidongUpdate(date)
          }

          if (updateBoolean) {
            if (info.status == '2' || info.status == '3') {
              this.chujiaIng(info.count, info.bidding, info.auctionPrice, info.markUpPrice)
            }
          }
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    // 到了开始使时间刷新页面
    zidongUpdate (date) {
      let date1 = new Date().getTime()
      let date2 = new Date(date).getTime()
      let time = Math.trunc(date2 - date1)
      if (time >= 0) {
        setTimeout(() => {
          this.getPage()
        }, time)
      }
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.borderContainer {
  min-height: 100%;
  background: #f6f6f6;
}

/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  .van-nav-bar__left  {
    padding: 0 0.4054rem;
  }
}
.my-swipe .van-swipe-item {
  height: 10.1351rem;

  .van-image {
    width: 100%;
    height: 100%;
  }
}
.box {
  padding: 0.2703rem;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 0.2703rem;
}
.shangpin {
  position: relative;
  &-name {
    font-size: 0.3784rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  &-price {
    margin-top: 0.2703rem;
    &-type {
      font-size: 0.3243rem;
      color: #FFA327;
    }
    &-num {
      font-size: 0.5405rem;
      color: #E54320;
    }
  }
  &-start {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 2.1622rem;
    font-size: 0.3243rem;
    display: flex;
    align-items: center;
    color: #fff;
    background: linear-gradient(to right, #EB4F47, #D62E25);
    padding: 0.2703rem;
    box-sizing: border-box;
    border-radius: 0.1892rem 0.1892rem 0 0;
    &-img {
      width: 0.2703rem;
      margin-right: 0.1351rem;
    }
    &-now {
      flex: 1;
    }
  }
  &-noneCanjia {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 2.1622rem;
    font-size: 0.3243rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    &-left {
      padding: 0.2703rem 0 0.2703rem 0.2703rem;
      flex: 1;
      background: linear-gradient(to right, #eb4f47, #f5665f);
      display: flex;
      align-items: center;
      border-radius: 0.1892rem 0 0 0;
      color: #fff;
    }
    &-right {
      padding: 0.2703rem;
      background: linear-gradient(to right, #f7d09e, #f6d9b3);
      display: flex;
      align-items: center;
      border-radius: 0 0.1892rem 0 0;
      &-jieshu {
        font-size: 0.3243rem;
      }
    }
    .colon {
      display: inline-block;
      margin: 0 0.1081rem;
      color: #000;
    }
    .block {
      display: inline-block;
      width: 0.5946rem;
      color: #000;
      font-size: 0.3243rem;
      text-align: center;
      background: #fff;
      border-radius: 0.0541rem;
    }
  }
}
.jingpaiReaords {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      font-size: 0.3784rem;
    }
    &-right {
      font-size: 0.3243rem;
    }
  }
  &-item {
    margin-top: 0.4054rem;
    display: flex;
    align-items: center;
    &-main {
      flex: 1;
      margin-left: 0.2703rem;
      &-detail {
        font-size: 0.3784rem;
        display: flex;
        align-items: center;
        &-two {
          flex: 1;
          font-size: 0.3243rem;
          display: flex;
          align-items: center;
          margin-left: 0.1351rem;
          &-text {
            color: #fff;
            background: #E54320;
            border-radius: 2.7027rem;
            padding: 0 0.2162rem;
            transform: scale(.8);
          }
        }
        &-three {
          color: #E54320;
        }
      }
      &-time {
        font-size: 0.3243rem;
        color: #777;
      }
    }
  }
}
.jine {
  &-item {
    font-size: 0.3243rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-type {
        color: #777;
      }
    }
  }
  &-main {
    margin: 0.1351rem 0;
  }
}
.southDetail {
  text-align: center;
  padding: 0 0.2703rem;
  box-sizing: border-box;
  &-img {
    width: 70%;
    margin: auto;
    vertical-align: middle;
  }
}
.commodityDetail {
  padding: 0.2703rem;
  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      border-top: 1px solid #000;
      width: 2.7027rem;
      height: 0;
    }
    span {
      margin: 0 0.2703rem;
    }
  }
  &-img {
    img {
      vertical-align: middle;
    }
  }
}
.goodsRules {
  &-rules {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2703rem;

    &-item {
      display: flex;
      align-items: center;

      &-left {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
          width: 1.3514rem;
          height: 1.3514rem;
        }

        &-text {
          margin-top: 0.1351rem;
          font-size: 0.3243rem;
          color: #777;
        }
      }

      &-margin {
        margin-right: 0.2703rem;
      }

      &-icon {
        transform: translateY(-0.2703rem);
      }
    }
  }
}
.daohang {
  width: 100%;
  position: fixed;
  background: #fff;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  &-start {
    font-size: 0.3243rem;
    display: flex;
    align-items: center;
    color: #fff;
    background: linear-gradient(to right, #EB4F47, #D62E25);
    border-radius: 0.1892rem 0.1892rem 0 0;
    padding: 0.2703rem;
    box-sizing: border-box;
    &-img {
      width: 0.2703rem;
      margin-right: 0.1351rem;
    }
    &-now {
      flex: 1;
    }
  }
  &-noneCanjia {
    font-size: 0.3243rem;
    padding: 0.2703rem;
    box-sizing: border-box;
    color: #000;
    background: linear-gradient(to right, #FBEFD8, #F6CAA3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-right {
      display: flex;
      align-items: center;
      &-jieshu {
        font-size: 0.3784rem;
      }
    }
    .colon {
      display: inline-block;
      margin: 0 0.1081rem;
      color: #000;
    }
    .block {
      display: inline-block;
      width: 0.5946rem;
      color: #000;
      font-size: 0.3243rem;
      text-align: center;
      background: #fff;
      border-radius: 0.0541rem;
    }
  }
  &-canpai {
    display: flex;
    align-items: center;
    padding: 0.2703rem;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-right: 0.5405rem;
      &-chat {
        font-size: 0.3243rem;
      }
    }
    &-left:active {
      background: #dfdede;
    }
    &-right {
      flex: 1;
      display: flex;
      align-items: center;
      &-btn {
        width: 100%;
        font-size: 0.3784rem;
        border-radius: 1.3514rem;
        padding: 0.2703rem 0;
        background: #E54320;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      &-btn:active {
        background: #fd3609;
      }
    }
  }
}
</style>