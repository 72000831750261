<template>
  <div>
    <van-action-sheet v-model="_ChuJia" title=" ">
      <dir class="chuJia-title">
        <span>当前价</span>
        <span style="color: #E54320;">{{ chujiainfo.bidding }}</span>
        <span>{{ chujiainfo.danwei }}{{ chujiainfo.auctionTypeName }}</span>
      </dir>

      <div class="chuJia">
        <p class="chuJia-chu">宝贝很抢手 请立即出价</p>

        <div class="chuJia-jiage">
          <div 
            :class="['chuJia-jiage-item', { chuJiaFocus: type == 1 }]" 
            style="margin-right: 20px;" @click="selectPi(1)">
            <span style="color: #E54320;">{{ chujiainfo.bidding }}</span>
            <span>{{ chujiainfo.danwei }}</span>
          </div>

          <div 
            :class="['chuJia-jiage-item', { chuJiaFocus: type == 2 }]" 
            style="margin-right: 20px;" @click="selectPi(2)">
            <span style="color: #E54320;">{{ chujiainfo.bidding * 2 }}</span>
            <span>{{ chujiainfo.danwei }}</span>
          </div>
        </div>
        <p style="font-size: 12px; color: #999;">双倍出价，中拍概率更高噢~</p>

        <div style="margin-top: 25px;">
          <van-button round block color="#E54320" @click="confirm">确认</van-button>
        </div>
      </div>
    </van-action-sheet>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>

import { chujia } from '@/api/Ge/port'
import { Toast } from 'vant'
import Loading from './Loading'

export default {
  components: { Loading },

  props: {
    ChuJia: {
      type: Boolean
    },
    chujiainfo: {
      type: Object
    }
  },

  computed: {
    _ChuJia: {
      get () {
        return this.ChuJia
      },
      set () {
        this.$emit('closeChuJia')
      }
    }
  },

  data () {
    return {
      type: 0,
      loading: false
    }
  },

  methods: {
    selectPi (type) {
      this.type = type
    },

    confirm () {
      if (this.type == 0) {
        Toast('请选择价格')
      } else {
        let data = { 
          id: this.chujiainfo.id,
          money: this.type == 1 ? this.chujiainfo.bidding : this.chujiainfo.bidding * 2
        }
        this.loading = true
        chujia(data).then(res => {
          if (res.data.code === 0) {
            this.loading = false
            Toast('已出价')
            this._ChuJia = false
            this.$emit('zhifuSuccess')
          } else {
            this.loading = false
            Toast(res.data.message)
          }
        })
      }
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.chuJia {
  padding: 15px;
  box-sizing: border-box;
  &-title {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-chu{
    font-size: 15px;
    color: #999;
    text-align: center;
  }
  &-jiage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    &-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #D8D8D8;
      background: #f3f3f3;
      border-radius: 5px;
      padding: 20px 15px;
      box-sizing: border-box;
    }
    .chuJiaFocus {
      border: 1px solid #E54320;
    }
  }
}
</style>